/*
Template Name: Nazox -  Admin & Dashboard Template
Author: Themesdesign
Version: 2.0.0
Contact: themesdesign.in@gmail.com
File: Main Css File
*/


//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "variables-dark";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/root";
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/color-picker";
@import "custom/plugins/session-timeout";
@import "custom/plugins/round-slider";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/datatable";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/x-editable";
@import "custom/plugins/leaflet-maps";

// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/chat";
@import "custom/pages/kanbanboard";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";

// icons
@import "custom/plugins/icons";


// @import "custom/rtl/general-rtl";
// @import "custom/rtl/bootstrap-rtl";
// @import "custom/rtl/spacing-rtl";
// @import "custom/rtl/float-rtl";
// @import "custom/rtl/text-rtl";
// @import "custom/rtl/structure-rtl";
// @import "custom/rtl/plugins-rtl";
// @import "custom/rtl/components-rtl";
// @import "custom/rtl/pages-rtl";
/*
@font-face {
    font-family: "EmBauhaus W00 Bold";
    src: url("https://db.onlinewebfonts.com/t/57c1c13b7064f0bc482654fd6409353e.eot");
    src: url("https://db.onlinewebfonts.com/t/57c1c13b7064f0bc482654fd6409353e.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/57c1c13b7064f0bc482654fd6409353e.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/57c1c13b7064f0bc482654fd6409353e.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/57c1c13b7064f0bc482654fd6409353e.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/57c1c13b7064f0bc482654fd6409353e.svg#EmBauhaus W00 Bold")format("svg");
}*/

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff0013' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
   }
   
   .carousel-control-next-icon {
     background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff0013' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
   }
   .carousel-indicators [data-bs-target] {
    background-color: #ff0013 !important;
   }




   .red {
    .fun-fact-block .fun-value, .timeline-item .item-period, .block-title h3 span, .site-title span, .blog-card .post-date span, .contact-info-block i, .ajax-page-nav > div.nav-item a:hover, .project-general-info .fa {
      color: #F44336 !important;
    }
    .btn-primary, .share-buttons a:hover, .header, .mobile-header, .testimonials.owl-carousel .owl-nav .owl-prev:hover, .testimonials.owl-carousel .owl-nav .owl-next:hover, .portfolio-grid figure a div:before, .portfolio-filters li.active a, .portfolio-filters li.active a:hover, .lm-pricing .lm-package-wrap.highlight-col .lm-heading-row span:after, .skill-percentage, .service-icon, .timeline-item:after, .start-page-content .page-header, .custom-page-content .page-header.color-1, .site-main-menu li a:after {
    background-color: #F44336 !important;
    }
    .btn-primary, .timeline-item, .timeline-item:before, .testimonial-content .testimonial-text, blockquote {
      border-color:#F44336 !important;
    }
  }
  
  .teal {
    .fun-fact-block .fun-value, .timeline-item .item-period, .block-title h3 span, .site-title span, .blog-card .post-date span, .contact-info-block i, .ajax-page-nav > div.nav-item a:hover, .project-general-info .fa {
      color: #26d9ac !important;
    }
    .btn-primary, .share-buttons a:hover, .header, .mobile-header, .testimonials.owl-carousel .owl-nav .owl-prev:hover, .testimonials.owl-carousel .owl-nav .owl-next:hover, .portfolio-grid figure a div:before, .portfolio-filters li.active a, .portfolio-filters li.active a:hover, .lm-pricing .lm-package-wrap.highlight-col .lm-heading-row span:after, .skill-percentage, .service-icon, .timeline-item:after, .start-page-content .page-header, .custom-page-content .page-header.color-1, .site-main-menu li a:after {
    background-color: #26d9ac !important;
    }
    .btn-primary, .timeline-item, .timeline-item:before, .testimonial-content .testimonial-text, blockquote {
      border-color:#26d9ac !important;
    }
  }
  
  .purple {
    .fun-fact-block .fun-value, .timeline-item .item-period, .block-title h3 span, .site-title span, .blog-card .post-date span, .contact-info-block i, .ajax-page-nav > div.nav-item a:hover, .project-general-info .fa {
      color: #AA00FF !important;
    }
    .btn-primary, .share-buttons a:hover, .header, .mobile-header, .testimonials.owl-carousel .owl-nav .owl-prev:hover, .testimonials.owl-carousel .owl-nav .owl-next:hover, .portfolio-grid figure a div:before, .portfolio-filters li.active a, .portfolio-filters li.active a:hover, .lm-pricing .lm-package-wrap.highlight-col .lm-heading-row span:after, .skill-percentage, .service-icon, .timeline-item:after, .start-page-content .page-header, .custom-page-content .page-header.color-1, .site-main-menu li a:after {
    background-color: #AA00FF !important;
    }
    .btn-primary, .timeline-item, .timeline-item:before, .testimonial-content .testimonial-text, blockquote {
      border-color:#AA00FF !important;
    }
  }
  
  .pink {
    .fun-fact-block .fun-value, .timeline-item .item-period, .block-title h3 span, .site-title span, .blog-card .post-date span, .contact-info-block i, .ajax-page-nav > div.nav-item a:hover, .project-general-info .fa {
      color: #E91E63 !important;
    }
    .btn-primary, .share-buttons a:hover, .header, .mobile-header, .testimonials.owl-carousel .owl-nav .owl-prev:hover, .testimonials.owl-carousel .owl-nav .owl-next:hover, .portfolio-grid figure a div:before, .portfolio-filters li.active a, .portfolio-filters li.active a:hover, .lm-pricing .lm-package-wrap.highlight-col .lm-heading-row span:after, .skill-percentage, .service-icon, .timeline-item:after, .start-page-content .page-header, .custom-page-content .page-header.color-1, .site-main-menu li a:after {
    background-color: #E91E63 !important;
    }
    .btn-primary, .timeline-item, .timeline-item:before, .testimonial-content .testimonial-text, blockquote {
      border-color:#E91E63 !important;
    }
  }
  
  .orange {
    .fun-fact-block .fun-value, .timeline-item .item-period, .block-title h3 span, .site-title span, .blog-card .post-date span, .contact-info-block i, .ajax-page-nav > div.nav-item a:hover, .project-general-info .fa {
      color: #FF9800 !important;
    }
    .btn-primary, .share-buttons a:hover, .header, .mobile-header, .testimonials.owl-carousel .owl-nav .owl-prev:hover, .testimonials.owl-carousel .owl-nav .owl-next:hover, .portfolio-grid figure a div:before, .portfolio-filters li.active a, .portfolio-filters li.active a:hover, .lm-pricing .lm-package-wrap.highlight-col .lm-heading-row span:after, .skill-percentage, .service-icon, .timeline-item:after, .start-page-content .page-header, .custom-page-content .page-header.color-1, .site-main-menu li a:after {
    background-color: #FF9800 !important;
    }
    .btn-primary, .timeline-item, .timeline-item:before, .testimonial-content .testimonial-text, blockquote {
      border-color:#FF9800 !important;
    }
  }
  .lime {
    .fun-fact-block .fun-value, .timeline-item .item-period, .block-title h3 span, .site-title span, .blog-card .post-date span, .contact-info-block i, .ajax-page-nav > div.nav-item a:hover, .project-general-info .fa {
      color: #9dd100 !important;
    }
    .btn-primary, .share-buttons a:hover, .header, .mobile-header, .testimonials.owl-carousel .owl-nav .owl-prev:hover, .testimonials.owl-carousel .owl-nav .owl-next:hover, .portfolio-grid figure a div:before, .portfolio-filters li.active a, .portfolio-filters li.active a:hover, .lm-pricing .lm-package-wrap.highlight-col .lm-heading-row span:after, .skill-percentage, .service-icon, .timeline-item:after, .start-page-content .page-header, .custom-page-content .page-header.color-1, .site-main-menu li a:after {
    background-color: #9dd100 !important;
    }
    .btn-primary, .timeline-item, .timeline-item:before, .testimonial-content .testimonial-text, blockquote {
      border-color:#9dd100 !important;
    }
  }
  .lightblue {
    .fun-fact-block .fun-value, .timeline-item .item-period, .block-title h3 span, .site-title span, .blog-card .post-date span, .contact-info-block i, .ajax-page-nav > div.nav-item a:hover, .project-general-info .fa {
      color: #29B6F6 !important;
    }
    .btn-primary, .share-buttons a:hover, .header, .mobile-header, .testimonials.owl-carousel .owl-nav .owl-prev:hover, .testimonials.owl-carousel .owl-nav .owl-next:hover, .portfolio-grid figure a div:before, .portfolio-filters li.active a, .portfolio-filters li.active a:hover, .lm-pricing .lm-package-wrap.highlight-col .lm-heading-row span:after, .skill-percentage, .service-icon, .timeline-item:after, .start-page-content .page-header, .custom-page-content .page-header.color-1, .site-main-menu li a:after {
    background-color: #29B6F6 !important;
    }
    .btn-primary, .timeline-item, .timeline-item:before, .testimonial-content .testimonial-text, blockquote {
      border-color:#29B6F6 !important;
    }
  }
  .green {
    .fun-fact-block .fun-value, .timeline-item .item-period, .block-title h3 span, .site-title span, .blog-card .post-date span, .contact-info-block i, .ajax-page-nav > div.nav-item a:hover, .project-general-info .fa {
      color: #2eca7f !important;
    }
    .btn-primary, .share-buttons a:hover, .header, .mobile-header, .testimonials.owl-carousel .owl-nav .owl-prev:hover, .testimonials.owl-carousel .owl-nav .owl-next:hover, .portfolio-grid figure a div:before, .portfolio-filters li.active a, .portfolio-filters li.active a:hover, .lm-pricing .lm-package-wrap.highlight-col .lm-heading-row span:after, .skill-percentage, .service-icon, .timeline-item:after, .start-page-content .page-header, .custom-page-content .page-header.color-1, .site-main-menu li a:after {
    background-color: #2eca7f !important;
    }
    .btn-primary, .timeline-item, .timeline-item:before, .testimonial-content .testimonial-text, blockquote {
      border-color:#2eca7f !important;
    }
  }
  .blue {
    .fun-fact-block .fun-value, .timeline-item .item-period, .block-title h3 span, .site-title span, .blog-card .post-date span, .contact-info-block i, .ajax-page-nav > div.nav-item a:hover, .project-general-info .fa {
      color: #2196F3 !important;
    }
    .btn-primary, .share-buttons a:hover, .header, .mobile-header, .testimonials.owl-carousel .owl-nav .owl-prev:hover, .testimonials.owl-carousel .owl-nav .owl-next:hover, .portfolio-grid figure a div:before, .portfolio-filters li.active a, .portfolio-filters li.active a:hover, .lm-pricing .lm-package-wrap.highlight-col .lm-heading-row span:after, .skill-percentage, .service-icon, .timeline-item:after, .start-page-content .page-header, .custom-page-content .page-header.color-1, .site-main-menu li a:after {
    background-color: #2196F3 !important;
    }
    .btn-primary, .timeline-item, .timeline-item:before, .testimonial-content .testimonial-text, blockquote {
      border-color:#2196F3 !important;
    }
  }
  .amber {
    .fun-fact-block .fun-value, .timeline-item .item-period, .block-title h3 span, .site-title span, .blog-card .post-date span, .contact-info-block i, .ajax-page-nav > div.nav-item a:hover, .project-general-info .fa {
      color: #FFC107 !important;
    }
    .btn-primary, .share-buttons a:hover, .header, .mobile-header, .testimonials.owl-carousel .owl-nav .owl-prev:hover, .testimonials.owl-carousel .owl-nav .owl-next:hover, .portfolio-grid figure a div:before, .portfolio-filters li.active a, .portfolio-filters li.active a:hover, .lm-pricing .lm-package-wrap.highlight-col .lm-heading-row span:after, .skill-percentage, .service-icon, .timeline-item:after, .start-page-content .page-header, .custom-page-content .page-header.color-1, .site-main-menu li a:after {
    background-color: #FFC107 !important;
    }
    .btn-primary, .timeline-item, .timeline-item:before, .testimonial-content .testimonial-text, blockquote {
      border-color:#FFC107 !important;
    }
  }
  .deeppurple {
    .fun-fact-block .fun-value, .timeline-item .item-period, .block-title h3 span, .site-title span, .blog-card .post-date span, .contact-info-block i, .ajax-page-nav > div.nav-item a:hover, .project-general-info .fa {
      color: #7e6df6 !important;
    }
    .btn-primary, .share-buttons a:hover, .header, .mobile-header, .testimonials.owl-carousel .owl-nav .owl-prev:hover, .testimonials.owl-carousel .owl-nav .owl-next:hover, .portfolio-grid figure a div:before, .portfolio-filters li.active a, .portfolio-filters li.active a:hover, .lm-pricing .lm-package-wrap.highlight-col .lm-heading-row span:after, .skill-percentage, .service-icon, .timeline-item:after, .start-page-content .page-header, .custom-page-content .page-header.color-1, .site-main-menu li a:after {
    background-color: #7e6df6 !important;
    }
    .btn-primary, .timeline-item, .timeline-item:before, .testimonial-content .testimonial-text, blockquote {
      border-color:#7e6df6 !important;
    }
  }
  .deeporange {
    .fun-fact-block .fun-value, .timeline-item .item-period, .block-title h3 span, .site-title span, .blog-card .post-date span, .contact-info-block i, .ajax-page-nav > div.nav-item a:hover, .project-general-info .fa {
      color: #FF5722 !important;
    }
    .btn-primary, .share-buttons a:hover, .header, .mobile-header, .testimonials.owl-carousel .owl-nav .owl-prev:hover, .testimonials.owl-carousel .owl-nav .owl-next:hover, .portfolio-grid figure a div:before, .portfolio-filters li.active a, .portfolio-filters li.active a:hover, .lm-pricing .lm-package-wrap.highlight-col .lm-heading-row span:after, .skill-percentage, .service-icon, .timeline-item:after, .start-page-content .page-header, .custom-page-content .page-header.color-1, .site-main-menu li a:after {
    background-color: #FF5722 !important;
    }
    .btn-primary, .timeline-item, .timeline-item:before, .testimonial-content .testimonial-text, blockquote {
      border-color:#FF5722 !important;
    }
  }

